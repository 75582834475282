import * as React from 'react';
import { useRef, useState } from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  BoxPic,
  ColumnStart,
  FormPage,
  FormPageGroup,
  GridColumn,
  GridContainer,
  Input,
  PicturePicker,
  Select,
  SwitchPanel,
} from '@src/components';
import { dispatchActionAppUpdateState } from '@src/state';
import { t } from '@src/translations';
import { santaClient } from '@src/state/client';
import { validateField } from '@src/utils/validators';
import { getBoxPath, getBoxPictures, getEditBoxData } from '@src/pages/box/boxHelpers';
import { getCurrencies } from '@src/types/currency';
import { FileUpload } from '@src/components/FileUpload';

export const BoxSettingsComponent = (props: BoxPageProps) => {
  const [error, setError] = useState(undefined);
  const [loaderId, setLoaderId] = useState(undefined);

  const fileUpload = useRef(null);
  const saveSettings = async () => {
    const res = await santaClient.updateBox(props.box.editBox);
    if (res.ok) {
      dispatchActionAppUpdateState({
        box: {
          info: res.data,
          editBox: getEditBoxData(res.data),
          saved: 'box_settings_saved',
        },
      });
      setError(undefined);
    } else {
      setError(t(res.error.message, { data: res.error.data }));
    }
  };

  const { box } = props.box.info;
  const input = props.box.editBox;
  const currencies = getCurrencies();

  const onFileStateChanged = async (state, file) => {
    if (state === 'uploaded') {
      dispatchActionAppUpdateState({
        box: { editBox: { logo: file.url, picture: 'custom_image' } },
      });
      setTimeout(() => {
        setLoaderId(null);
      }, 500);
    }
  };

  return (
    <FormPage
      title="box_settings_title"
      onSubmit={box.isArchived ? null : saveSettings}
      buttonSubmit={box.isArchived ? null : 'box_settings_save_button'}
      buttonBack="box_settings_back_button"
      onBack={() => props.history.push(getBoxPath(''))}
      translator={props.translator}
      error={error}
    >
      <FormPageGroup title="box_settings_box_name">
        <Input
          label="box_settings_box_name"
          disabled={box.isArchived}
          value={input.name}
          validators={[validateField(input, 'name')]}
          noBottomText
          onChange={(name) => {
            dispatchActionAppUpdateState({ box: { editBox: { name } } });
          }}
        />
      </FormPageGroup>
      <FormPageGroup title="box_settings_box_picture" overflowScroll>
        <PicturePicker
          noWrap
          disabled={box.isArchived}
          selected={input.picture}
          onChange={async (picture) => {
            const pic = typeof picture !== 'string' ? 'custom_image' : picture;
            dispatchActionAppUpdateState({ box: { editBox: { picture: pic } } });
            if (pic === 'custom_image') {
              setTimeout(() => {
                setLoaderId('custom_image');
              }, 500);
              fileUpload.current.open();
            }
          }}
          loader={loaderId}
        >
          {getBoxPictures(props.box.info).map((type) => {
            const logo = input.logo || box.logo;
            if (type === 'custom_image' && logo) {
              return (
                <img key={type} alt="custom_image" style={{ borderRadius: '100%' }} src={logo} />
              );
            }
            return <BoxPic key={type} type={type} />;
          })}
        </PicturePicker>
      </FormPageGroup>
      <FormPageGroup title="new_box_step_cost_title">
        <SwitchPanel
          disabled={box.isArchived}
          title="new_box_step_cost_switch_title"
          hint="new_box_step_cost_switch_hint"
          value={input.useCashLimit}
          onChange={(useCashLimit) => {
            dispatchActionAppUpdateState({ box: { editBox: { useCashLimit } } });
          }}
        />
        {input.useCashLimit ? (
          <GridContainer spacing={2}>
            <GridColumn xs={3}>
              <Input
                disabled={box.isArchived}
                label="new_box_step_cash_limit_min"
                value={input.cashLimitMin}
                max={6}
                number
                validators={[validateField(input, 'cashLimitMin', 'useCashLimit')]}
                onChange={(cashLimitMin) => {
                  dispatchActionAppUpdateState({ box: { editBox: { cashLimitMin } } });
                }}
              />
            </GridColumn>
            <GridColumn xs={3}>
              <Input
                disabled={box.isArchived}
                label="new_box_step_cash_limit_max"
                value={input.cashLimit}
                max={6}
                number
                validators={[validateField(input, 'cashLimit', 'useCashLimit')]}
                onChange={(cashLimit) => {
                  dispatchActionAppUpdateState({ box: { editBox: { cashLimit } } });
                }}
              />
            </GridColumn>
            <GridColumn xs={6}>
              <Select
                disabled={box.isArchived}
                label="currency_title"
                options={currencies}
                value={input.cashLimitCurrency}
                validators={[validateField(input, 'cashLimitCurrency', 'useCashLimit')]}
                onChange={(cashLimitCurrency) => {
                  dispatchActionAppUpdateState({ box: { editBox: { cashLimitCurrency } } });
                }}
              />
            </GridColumn>
          </GridContainer>
        ) : (
          <ColumnStart style={{ height: '5.85rem' }} />
        )}
      </FormPageGroup>
      <FormPageGroup title="new_box_step_info_title">
        <SwitchPanel
          disabled={box.isArchived}
          title="new_box_step_info_use_wish"
          hint="new_box_step_info_use_wish_hint"
          value={input.useWish}
          onChange={(useWish) => {
            dispatchActionAppUpdateState({ box: { editBox: { useWish } } });
          }}
        />
        <SwitchPanel
          disabled={box.isArchived}
          title="new_box_step_info_use_post"
          hint="new_box_step_info_use_post_hint"
          value={input.usePost}
          onChange={(usePost) => {
            dispatchActionAppUpdateState({ box: { editBox: { usePost } } });
          }}
        />
        <SwitchPanel
          disabled={box.isArchived}
          title="new_box_step_info_phone_required"
          hint="new_box_step_info_phone_required_hint"
          value={input.isPhoneRequired}
          onChange={(isPhoneRequired) => {
            dispatchActionAppUpdateState({ box: { editBox: { isPhoneRequired } } });
          }}
        />
        <SwitchPanel
          disabled={box.isArchived}
          title="new_box_step_info_use_names"
          hint="new_box_step_info_use_names_hint"
          value={input.useNames}
          onChange={(useNames) => {
            dispatchActionAppUpdateState({ box: { editBox: { useNames } } });
          }}
        />
      </FormPageGroup>
      <FileUpload
        ref={fileUpload}
        onChange={onFileStateChanged}
        maxSize={1024 * 250}
        fileLargeMsg="modal_large_file_message_250kb"
        accept="image/*"
      />
    </FormPage>
  );
};
