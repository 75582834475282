import * as React from 'react';
import { _, link, linkSeparateTab, linkSmall } from '@src/translations/utils.translations';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import {
  ButtonSecondary,
  MyCardAction,
  RowCenter,
  SantaLink,
  SimpleLink,
  TextH3Semi,
  TextMedium,
} from '@src/components';
import retryTimes = jest.retryTimes;

export const CARD_TRANSLATIONS = {
  card_no_card_title_archived_box: _(
    'Вы не создавали карточку для этой игры, тут нечего смотреть.',
    "You haven't created a card for this game, there's nothing to see here.",
  ),
  card_no_card_title: _('Помогите своему Санте с выбором подарка', 'Help your Santa choose a gift'),
  card_no_card_hint: _(
    'Заполните карточку, чтобы принять участие в игре.',
    'Fill out the card to participate in the game.',
  ),
  card_no_card_active_draw_title: _(
    'Вы не участвуете в игре',
    'You are not participating in the game',
  ),
  card_no_card_active_draw_hint: _(
    'Жеребьевка прошла без вас так как вы не создали себе карточку участника либо она была удалена.',
    "The draw took place without you because you didn't create a participant card or it was deleted.",
  ),
  card_no_card_not_logged_in_active_draw_title: _(
    'Войдите на сайт, чтобы просмотреть карточку',
    'Login to view card',
  ),
  card_no_card_active_draw_hint_not_registered: _(
    (loginLink, isLoggedIn) => {
      const wrongAccount = (
        <>
          Похоже, что вы зашли на сайт <i>не под тем аккаунтом</i>, с которым вы регистрировались в
          коробке.{' '}
        </>
      );
      return (
        <>
          <RowCenter>
            <b>Жеребьевка уже проведена</b>
          </RowCenter>
          <br />
          <b>Вы еще не создавали карточку и хотите участвовать?</b>
          <br />
          <br />
          Если вы еще не создавали себе карточку, но хотите участвовать в игре, обратитесь к
          организатору и попросите пригласить вас.
          <br />
          <br />
          <b>Вы уже создавали себе карточку участника в этой коробке?</b>
          <br />
          <br />
          {isLoggedIn ? wrongAccount : null}
          Если вы уже создавали себе карточку участника -{' '}
          <SimpleLink href={loginLink}>
            <TextMedium orange underline>
              войдите на сайт
            </TextMedium>
          </SimpleLink>{' '}
          под тем же аккаунтом, с которым эта карточка была создана. Помните, что вы могли
          использовать соц. сети для входа.
          <br />
          <br />
          Если вы <i>не помните</i>, как вы регистрировались - свяжитесь с организатором и попросите
          его посмотреть, какой email указан у вас в карточке. Если там указан email - зайдите под
          ним. Если в карточке ничего не указано - это значит что вы заходили с помощью VKontakte
          или Telegram - попробуйте зайти. Если в карточке указана неверная почта - попросите вашего
          организатора изменить ее, тогда вам придет письмо со ссылкой уже на верную почту.
          <br />
          <br />
        </>
      );
    },
    (loginLink, isLoggedIn) => {
      const wrongAccount = (
        <>
          Похоже, что вы зашли на сайт <i>не под тем аккаунтом</i>, с которым вы регистрировались в
          коробке.{' '}
        </>
      );
      return (
        <>
          <br />
          <b>Вы еще не создавали карточку и хотите участвовать?</b>
          <br />
          <br />
          Если вы еще не создавали себе карточку, но хотите участвовать в игре, обратитесь к
          организатору и попросите пригласить вас.
          <br />
          <br />
          <b>Вы уже создавали себе карточку участника в этой коробке?</b>
          <br />
          <br />
          {isLoggedIn ? wrongAccount : null}
          Если вы уже создавали себе карточку участника -{' '}
          <SimpleLink href={loginLink}>
            <TextMedium orange underline>
              войдите на сайт
            </TextMedium>
          </SimpleLink>{' '}
          под тем же аккаунтом, с которым эта карточка была создана. Помните, что вы могли
          использовать соц. сети для входа.
          <br />
          <br />
          Если вы <i>не помните</i>, как вы регистрировались - свяжитесь с организатором и попросите
          его посмотреть, какой email указан у вас в карточке. Если там указан email - зайдите под
          ним. Если в карточке ничего не указано - это значит что вы заходили с помощью VKontakte
          или Telegram - попробуйте зайти. Если в карточке указана неверная почта - попросите вашего
          организатора изменить ее, тогда вам придет письмо со ссылкой уже на верную почту.
          <br />
          <br />
        </>
      );
    },
  ),
  card_login_to_site: _('Войти на сайт', 'Login'),
  card_add_card: _('Создать карточку участника', 'Create a participant card'),
  card_fill_card: _('Заполнить карточку участника', 'Fill out the participant card'),
  card_created_need_verify_title: _('Письмо отправлено', 'Email sent'),
  card_created_need_verify_hint: _(
    'Вам на почту было отправлено письмо регистрации. Перейдите по ссылке из письма, чтобы подтвердить свой почтовый адрес.',
    'An email registration has been sent to your email. Click the link in the email to confirm your email address.',
  ),
  create_card_step_title: _('Шаг {step} из {total}', 'Step {step} of {total}'),
  create_card_step_name_title: _('Имя и контактные данные', 'Name and contact details'),
  create_card_username: _('Ваше имя или псевдоним', 'Your name'),
  create_card_username_hint: _(
    'Это имя (псевдоним) будут видеть все участники "Тайного Санты". Вы сможете поменять имя позже в настройках карточки.',
    'This name will be visible to all participants. You can change your name later in the card settings.',
  ),
  create_card_step_picture_title: _('Выберите обложку для карточки', 'Choose a cover for the card'),
  create_card_step_contacts_title: _('Контактные данные', 'Contact details'),
  create_card_phone: _('Номер телефона', 'Phone number'),
  create_card_phone_required_hint: _(
    'Телефон обязателен. Вы сможете поменять имя позже в настройках карточки.',
    'Phone is required. You can change it later in the card settings.',
  ),
  create_card_phone_hint: _(
    'Телефон необязателен. Вы сможете поменять телефон позже в настройках карточки.',
    'Phone is optional. You can change it later in the card settings.',
  ),
  create_card_step_post_address_title: _('Укажите почтовый адрес', 'Specify the mailing address'),
  create_card_post_recipient: _('Имя получателя', "Recipient's name"),
  create_card_post_address: _('Адрес', 'Address'),
  create_card_post_code: _('Почтовый индекс', 'Postal code'),
  create_card_step_wish_title: _('Напишите пожелания к подарку', 'Write gift wishes'),
  create_card_step_wish: _('Какой подарок вы хотите получить', 'Your gift wishes'),
  create_card_step_wish_hint_cash_limit: _(
    (min: number) => {
      if (min > 0) {
        return (
          <>
            Организатор установил ограничение на сумму подарка от <b>{min} до </b>
          </>
        );
      }
      return 'Организатор установил ограничение на сумму подарка в ';
    },
    (min) => {
      if (min > 0) {
        return (
          <>
            The organizer has set a limit on the gift amount from <b>{min} to </b>
          </>
        );
      }
      return `The organizer has set a limit on the gift amount to `;
    },
  ),
  create_card_step_wish_hint: _(
    'Расскажите о себе, какому подарку вы были бы рады или что точно не хотели бы получить. Вы сможете обновить пожелания даже после проведения жеребьевки — ваш Санта получит об этом уведомление.',
    "Tell about yourself, what gift you would like, or what you definitely wouldn't like to receive. You can update your wishes even after the draw is conducted – your Santa will be notified about it.",
  ),
  create_card_agreement: _(
    <>
      Заполняя форму ниже, вы даете свое согласие на{' '}
      {linkSeparateTab('/policy', 'обработку персональных данных')}.
      <br />
      <br />
      <b>Важно:</b> организатор жеребьевки и ваш Тайный Санта будут иметь доступ к оставленным вами
      данным. Сайт не несет ответственности за действия организатора жеребьевки или вашего будущего
      Тайного Санты. Если вы не готовы оставлять свои данные, пожалуйста, покиньте сайт.
    </>,
    <>
      By filling out the form below, you consent to the{' '}
      {linkSeparateTab('/policy', 'processing of personal data')}.
      <br />
      <br />
      <b>Important:</b> The organizer of the draw and your Secret Santa will have access to the
      information you provide. The website is not responsible for the actions of the draw organizer
      or your future Secret Santa. If you are not willing to provide your data, please leave the
      website.
    </>,
  ),
  create_card_step_login_title: _(
    <>
      Для того, чтобы вы могли создать карточку, необходимо зарегистрироваться.
      <br />
      <br />
      Если вы уже имеете аккаунт на сайте - {link('/login', 'войдите')}.
    </>,
    <>
      In order for you to create a card, you need to register.
      <br />
      <br />
      If you already have an account on the website, please {link('/login', 'log in')}.
    </>,
  ),
  create_card_step_wish_hint_cash_limit_2: _(
    ' Учитывайте это ограничение при написании своего пожелания.',
    ' Please consider this limitation when writing your wish.',
  ),
  card_my_card_title: _('Моя карточка', 'My card'),
  card_my_card_wish_title: _('Ваши пожелания', 'Your wishes'),
  card_my_card_no_wish_yet: _(
    () => (
      <p>
        <SantaLink to={getBoxPath('/card/edit')}>
          <ButtonSecondary>Добавить пожелания по подарку</ButtonSecondary>
        </SantaLink>
      </p>
    ),
    () => (
      <p>
        <SantaLink to={getBoxPath('/card/edit')}>
          <ButtonSecondary>Add wishes</ButtonSecondary>
        </SantaLink>
      </p>
    ),
  ),
  card_my_card_add_address: _(
    () => (
      <p>
        Вы ещё не добавили почтовый адрес. {linkSmall(getBoxPath('/card/edit'), 'Добавить адрес.')}
      </p>
    ),
    () => (
      <p>
        You haven't added a postal address yet.{' '}
        {linkSmall(getBoxPath('/card/edit'), 'Add address.')}
      </p>
    ),
  ),
  card_my_card_not_filled: _(
    'Карточка заполнена не полностью',
    'Card is not filled out completely',
  ),
  card_my_card_no_post_filled: _(
    'Вы не указали почтовый адрес',
    'You have not specified a postal address',
  ),
  card_my_card_fill: _('Заполнить карточку', 'Fill out the card'),
  card_my_card_update_wish: _('Обновить пожелания', 'Update wishes'),
  card_my_card_add_wish: _('Добавить пожелания по подарку.', 'Add wishes for gifts.'),
  edit_my_card_title: _('Настройки карточки', 'Card settings'),
  edit_my_card_save: _('Сохранить изменения', 'Save changes'),
  edit_my_card_back: _('Назад к карточке', 'Back to card'),
  edit_my_card_username_title: _('Имя и телефон', 'Name and phone'),
  edit_my_card_picture_title: _('Обложка для карточки', 'Card cover'),
  edit_my_card_delete_title: _('Удаление карточки', 'Delete card'),
  edit_my_card_delete_button: _('Удалить карточку', 'Delete card'),
  edit_my_card_delete_hint: _(
    'Вы можете удалить карточку, если не желаете участвовать в игре.',
    'You can delete the card if you no longer want to participate in the game.',
  ),
  edit_my_card_delete_confirm_word: _('Удалить карточку', 'Delete card'),
  edit_my_card_delete_after_draw_hint: _(
    'Вы не можете самостоятельно удалить карточку после проведения жеребьевки. Если вы передумали участвовать в игре, обратитесь к организатору, он сможет удалить вашу карточку.',
    'You cannot delete the card yourself after the draw. If you have changed your mind about participating in the game, contact the organizer, and they can delete your card.',
  ),
  edit_card_saved: _('Изменения сохранены', 'Changes have been saved'),
  edit_other_card_back: _('Назад к коробке', 'Back to box'),
  edit_other_card_save: _('Сохранить изменения', 'Save changes'),
  edit_other_card_delete_title: _('Удаление карточки участника', 'Delete participant card'),
  edit_other_card_delete_hint: _(
    'Вы можете удалить карточку участника, если он передумал участвовать в игре.',
    'You can delete a participant card if they have changed their mind about participating in the game.',
  ),
  edit_other_card_delete_after_draw_hint: _(
    'Вы можете удалить карточку участника, если он передумал участвовать в игре. Санте данного участника отправится письмо о смене подопечного — он будет дарить подарок подопечному текущего участника.',
    "You can delete a participant card if they have changed their mind about participating in the game. Santa of this participant will receive a notification about changing their recipient - they will be giving a gift to the current participant's recipient.",
  ),
  edit_other_card_delete_confirm_word: _('Удалить карточку', 'Delete card'),
  edit_other_card_delete_button: _('Удалить карточку', 'Delete card'),
  edit_other_card_title: _('Настройки карточки участника', 'Participant card settings'),
  edit_other_card_email: _('Email участника', "Participant's email"),
  edit_other_card_username_title: _('Имя и телефон участника', 'Name and phone of participant'),
  edit_other_card_username: _('Имя', 'Name'),
  edit_other_card_picture: _('Обложка участника', "Participant's cover"),
  edit_other_card_wish: _('Пожелания участника', "Participant's wishes"),
  edit_other_card_show_wish: _('Показать пожелания', 'Show wishes'),
  edit_other_card_wish_you_cannot_view: _(
    'Участник оставил пожелания, но вы не можете их просматривать.',
    'The participant has left wishes, but you cannot view them.',
  ),
  edit_other_card_wish_does_not_exist_not_verified: _(
    'Участник пока не подтвердил участия и поэтому не оставил пожеланий.',
    'The participant has not confirmed their participation yet and has not left any wishes.',
  ),
  edit_other_card_wish_exists: _(
    'Вы можете обновить пожелания самостоятельно или',
    'You can update the wishes yourself or',
  ),
  edit_other_card_wish_does_not_exist: _(
    'Участник пока не оставил пожеланий по подарку.',
    'The participant has not left any gift wishes yet.',
  ),
  edit_other_card_wish_ask_to_add: _(
    'Попросить участника написать пожелания.',
    'Ask the participant to write gift wishes.',
  ),
  edit_other_card_wish_ask_to_update: _(
    'попросить участника обновить пожелания.',
    'ask the participant to update their wishes.',
  ),
  edit_other_card_address_not_exists_not_verified: _(
    'Участник пока не подтвердил участия и поэтому не заполнил почтовый адрес.',
    'The participant has not confirmed their participation yet and has not filled in the postal address.',
  ),
  edit_other_card_address_not_exists_hint: _(
    'Если вы знаете почтовый адрес участника, вы можете добавить его сами. Также, вы можете',
    "If you know the participant's postal address, you can add it yourself. Also, you can",
  ),
  edit_other_card_address_exists_hint: _(
    'Вы можете обновить почтовый адрес, если он некорректный. Также, вы можете',
    'You can update the postal address if it is incorrect. Also, you can',
  ),
  edit_other_card_address_ask_to_add: _(
    'попросить участника добавить почтовый адрес.',
    'ask the participant to add the postal address.',
  ),
  edit_other_card_address_ask_to_update: _(
    'попросить участника обновить почтовый адрес.',
    'ask the participant to update the postal address.',
  ),
  edit_other_card_postal_address: _('Почтовый адрес участника', "Participant's postal address"),
  edit_other_card_cannot_edit_email: _(
    'Участник уже подтвердил свой email, вы не можете его изменить.',
    'The participant has already confirmed their email, you cannot change it.',
  ),
  edit_other_card_can_edit_email_if_verified: _(
    'Участник уже подтвердил свой email, вы не можете его изменить.',
    'The participant has already confirmed their email, you cannot change it.',
  ),
  edit_other_card_can_edit_email_if_not_verified: _(
    'Вы можете изменить адрес участника. После сохранения, письма переотправятся на новый адрес.',
    "You can change the participant's address. After saving, emails will be resent to the new address.",
  ),
  edit_other_card_poke_title: _('Текст уведомления', 'Message'),
  edit_other_card_ask_to_add_wish: _(
    'Пожалуйста, добавь пожелания по подарку.',
    'Please add gift wishes.',
  ),
  edit_other_card_ask_to_update_wish: _(
    'Пожалуйста, обнови пожелания по подарку.',
    'Please update gift wishes.',
  ),
  edit_other_card_ask_to_add_address: _(
    'Пожалуйста, добавь почтовый адрес.',
    'Please add a postal address.',
  ),
  edit_other_card_ask_to_update_address: _(
    'Пожалуйста, обнови почтовый адрес.',
    'Please update the postal address.',
  ),
  edit_other_card_poke_button: _('Отправить уведомление', 'Send notification'),
  admin_poke_sent: _('Уведомление отправлено', 'Notification sent'),
  card_chat_title: _('Чат с Тайным Сантой', 'Chat with Secret Santa'),
  card_chat_placeholder_gift: _(
    'Сообщите о получении подарка и оставьте отзыв',
    'Notify about receiving a gift and leave feedback',
  ),
  card_chat_placeholder: _(
    'Введите сообщение для Тайного Санты',
    'Enter a message for Secret Santa',
  ),
  card_chat_gift_received_message: _('Подарок получен!', 'Gift received!'),
  card_chat_received_hint: _(
    'Если вы уже получили подарок, сообщите об этом своему Санте, нажав на кнопку.',
    'If you have already received a gift, let your Santa know by clicking the button.',
  ),
  card_chat_received_button: _('Я получил подарок', 'I have received the gift'),
  card_info_updated: _(
    'Почтовый адрес и пожелания изменились.',
    'The postal address and wishes have been updated.',
  ),
  card_info_wish_updated: _('Пожелания изменились.', 'Wishes have been updated.'),
  card_info_address_updated: _('Почтовый адрес изменился.', 'The postal address has been updated.'),
  card_info_updated_admin: _(
    'Организатор изменил почтовый адрес и пожелания.',
    'The organizer has updated the postal address and wishes.',
  ),

  card_info_wish_updated_admin: _(
    'Организатор изменил пожелания.',
    'The organizer has updated the wishes.',
  ),
  card_info_address_updated_admin: _(
    'Организатор изменил почтовый адрес.',
    'The organizer has updated the postal address.',
  ),

  card_show_contacts: _('Показать контакты', 'Show contacts'),
  card_back_to_wish: _('Показать пожелания', 'Show wishes'),
  card_no_contact_yet: _(
    'Вы пока что не оставили никаких контактных данных',
    'You have not left any contact information yet',
  ),
  card_i_sent_gift: _('Я отправил подарок', 'I sent the gift'),
  card_you_sent_gift: _('Вы отправили подарок', 'You sent the gift'),
  card_write_to_ward: _('Написать подопечному', 'Write to the giftee'),
  card_i_got_the_gift: _('Я получил подарок', 'I got the gift'),
  card_you_got_the_gift: _('Вы получили подарок', 'You got the gift'),
  card_open_chat_with_santa: _('Открыть чат с Сантой', 'Open chat with Santa'),
};
