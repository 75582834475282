import * as React from 'react';
import { connect } from 'react-redux';
import { ColumnCenter, Footer, FooterItem, RowEnd, SantaLink, TextMedium } from '@src/components';
import { VkLike } from '@src/vk/VkLike';
import { SantaAppState } from '@src/state';
import { t, TRANSLATIONS } from '@src/translations';
import { isDotCom } from '@src/host';

type FooterProps = {
  css?: any;
};

function FooterInternal(props: FooterProps) {
  const translator = t;
  return (
    <div>
      <Footer
        rightItems={
          props.css.mobile ? (
            <ColumnCenter>
              <VkLike />
              <TextMedium style={{ marginTop: '1rem' }} white>
                © 2014-2023 Santa-Secret
              </TextMedium>
            </ColumnCenter>
          ) : (
            <RowEnd>
              <VkLike />
              <TextMedium white>© 2014-2023 Santa-Secret</TextMedium>
            </RowEnd>
          )
        }
      >
        <SantaLink to="/randomizer">
          <FooterItem translator={translator}>footer_quick_draw</FooterItem>
        </SantaLink>
        <SantaLink to="/faq">
          <FooterItem translator={translator}>footer_faq</FooterItem>
        </SantaLink>
        {isDotCom() ? null : (
          <>
            {/* TODO return when gifts are back <SantaLink to="/gifts"> */}
            {/*  <FooterItem translator={translator}>footer_gift_ideas</FooterItem> */}
            {/* </SantaLink> */}
            <SantaLink to="/donate">
              <FooterItem translator={translator}>footer_support</FooterItem>
            </SantaLink>
          </>
        )}
        <SantaLink to="/resend">
          <FooterItem translator={translator}>footer_no_email</FooterItem>
        </SantaLink>
        <SantaLink to="/feedback">
          <FooterItem translator={translator}>footer_feedback</FooterItem>
        </SantaLink>
        <SantaLink to="/policy">
          <FooterItem translator={translator}>footer_policy</FooterItem>
        </SantaLink>
      </Footer>
    </div>
  );
}

function mapStateToFooterProps(state: SantaAppState) {
  return {
    css: state.css,
  };
}

export const FooterComponent = connect(mapStateToFooterProps)(FooterInternal);
